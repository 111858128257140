.login_page {
  width: 460px;
  // height: 550px;
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 40px;
}
.login_select {
  width: 380px;
  height: 50px;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE5IDguNUwxMiAxNS41TDUgOC41IiBzdHJva2U9IiMxODE4MTgiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==")
    no-repeat right 8.5px center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0px 8px;
}
