@tailwind base;
@tailwind components;
@tailwind utilities;

$primary: #1c274c;

@font-face {
  font-family: "thin";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Thin.woff")
    format("woff");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "extraLight";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-ExtraLight.woff")
    format("woff");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "light";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Light.woff")
    format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "regular";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff")
    format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "semiBold";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-SemiBold.woff")
    format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "bold";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Bold.woff")
    format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "extraBold";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-ExtraBold.woff")
    format("woff");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "black";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Black.woff")
    format("woff");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "gmarketSansBold";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansBold.woff")
    format("woff");
  font-style: normal;
}

// prettier-ignore
* {font-family: "regular";
  .thin {font-family: "thin"}
  .extraLight {font-family: "extraLight"}
  .light {font-family: "light"}
  .semiBold {font-family: "semiBold"}
  .bold {font-family: "bold"}
  .extraBold {font-family: "extraBold"}
  .black {font-family: "black"}
  .gmarketSansBold {font-family: "gmarketSansBold"}
}

.transition03s {
  transition: all 0.3s;
}

.p-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.login-bg {
  background-image: url(../public/imgs/bg.png);
}

.ell {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.quill {
  background-color: white;
}

// 스크롤바
.pretty-scrollbar {
  &::-webkit-scrollbar {
    width: 7px;
    background-color: transparent;

    &-thumb {
      background-color: rgb(143, 143, 143);
      height: 5px;
      border-radius: 50px;
    }
    &-track {
      background-color: rgba(0, 0, 0, 0);
    }
  }
}

// quill
.basic {
  .ql-editor {
    min-height: 180px;
    overflow: auto;
    max-height: 600px;
  }
}
.plane_quill {
  background-color: transparent;
  .ql-container.ql-snow {
    border: none;

    .ql-editor {
      overflow: auto;
      padding: 0;
    }
  }
}
.quill > .ql-container > .ql-editor.ql-blank {
  padding: 8px;
}
.quill > .ql-container > .ql-editor {
  padding: 8px;
}
.quill > .ql-container > .ql-editor.ql-blank::before {
  left: 8px;
  right: 0;
  font-style: normal;
  font-size: 14px;
  color: #9ca3af;
  font-weight: 500;
}
.ant-switch {
  background-color: rgba(0, 0, 0, 0.45);
}

// ====================== antd ======================

// 시간선택 ok버튼
.ant-btn-primary {
  background-color: lightgray;
}

// 정렬시 강조 제거
// 로우
:where(.css-dev-only-do-not-override-k83k30).ant-table-wrapper
  td.ant-table-column-sort,
:where(.css-k83k30).ant-table-wrapper td.ant-table-column-sort {
  background: transparent;
}
// 컬럼
:where(.css-dev-only-do-not-override-k83k30).ant-table-wrapper
  .ant-table-thead
  th.ant-table-column-sort,
:where(.css-k83k30).ant-table-wrapper
  .ant-table-thead
  th.ant-table-column-sort {
  background: #fafafa;
}

:where(.css-dev-only-do-not-override-k83k30).ant-tooltip,
:where(.css-k83k30).ant-tooltip {
  max-width: 300px;
}

// 기업관리자 카테고리 테이블 보더
#category_table {
  .ant-table-wrapper
    .ant-table:not(.ant-table-bordered)
    .ant-table-tbody
    > tr
    > td {
    border: 1px solid black;
  }
  .ant-table-wrapper table {
    border-collapse: collapse;
  }
  .ant-table-wrapper table thead tr th {
    border: 1px solid black;
  }
}

// ====================== antd ======================

#sumTable {
  width: 1200px;
  text-align: center;
  box-sizing: border-box;
  font-size: 14px;
  thead {
    tr {
      th {
        border-bottom: 1px solid #f0f0f0;
        padding: 16px;
        background: #fafafa;
        border-bottom: 1px solid #f0f0f0;
      }
      :first-child {
        border-start-start-radius: 8px;
      }
      :last-child {
        border-start-end-radius: 8px;
      }
    }
  }
  tbody {
    tr {
      td {
        background: white;
        border: 1px solid #f0f0f0;
        height: 54px;
        &.gray {
          background-color: #eee;
        }
      }
    }
  }
}

#starTable {
  text-align: center;
  box-sizing: border-box;
  width: 1200px;
  thead {
    box-sizing: border-box;
    font-weight: 700;
    font-size: 14px;
    td {
      border-bottom: 1px solid #f0f0f0;
      padding: 16px;
      background: #fafafa;
      border-bottom: 1px solid #f0f0f0;
    }
    :first-child {
      border-start-start-radius: 8px;
    }
    :last-child {
      border-start-end-radius: 8px;
    }
  }
  tbody {
    tr {
      td {
        background: white;
        border: 1px solid #f0f0f0;
        height: 54px;
      }
    }
  }
}

#addup-table {
  td {
    padding: 0;
  }
}

#starBar {
  max-height: 600px;
  overflow-y: scroll;
}

#agreeTable {
  table {
    width: 100%;
    border-collapse: collapse;
  }

  th {
    border: 1px solid black;
  }

  td {
    padding: 10px;
    border: 1px solid black;
    text-align: center;
  }
}
