input[type="checkbox"] {
  display: none;
}
input[type="checkbox"] + label {
  display: inline-block;
  cursor: pointer;
  line-height: 24px;
  padding-left: 28px;
  border: 0;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' %3E%3Cpath fillRule='evenodd' clipRule='evenodd' d='M16.334 2.75H7.665C4.644 2.75 2.75 4.889 2.75 7.916V16.084C2.75 19.111 4.635 21.25 7.665 21.25H16.333C19.364 21.25 21.25 19.111 21.25 16.084V7.916C21.25 4.889 19.364 2.75 16.334 2.75Z' stroke='%23494949' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round' /%3E%3Cpath d='M8.44141 12L10.8154 14.373L15.5614 9.62695' stroke='%23494949' troke-width='1.5' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/svg%3E")
    no-repeat 0 0px / contain;
}

input[type="checkbox"]:checked + label {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' %3E%3Cpath fillRule='evenodd' clipRule='evenodd' d='M16.334 2.75H7.665C4.644 2.75 2.75 4.889 2.75 7.916V16.084C2.75 19.111 4.635 21.25 7.665 21.25H16.333C19.364 21.25 21.25 19.111 21.25 16.084V7.916C21.25 4.889 19.364 2.75 16.334 2.75Z' stroke='%234CAF50' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' /%3E%3Cpath d='M8.44141 12L10.8154 14.373L15.5614 9.62695' stroke='%234CAF50' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' /%3E%3C/svg%3E")
    no-repeat;
}
